import product from '@/components/product/index.vue'
import {mapActions, mapGetters} from "vuex";

export default {
  name: "favorites-list",
  components: {
    product
  },
  data(){
    return{
    activeCategory: '',
    }
  },
  created() {
    this.fetchFavoriteList().then(()=>{
      // console.log(this.whichList);
    })
  },
  computed: {
    ...mapGetters({
      whichList: 'favorites/whichList'
    }),
    products(){
      let products = []
      for(let i = 0; i < this.whichList.length; i++){
        for(let k = 0; k < this.whichList[i].products.length; k++){
          if(this.activeCategory !== ''){
            if(this.whichList[i].id === this.activeCategory){
              products.push(this.whichList[i].products[k]);
            }
          }else {
            // console.log(this.whichList[i]);
            products.push(this.whichList[i].products[k]);
          }
        }
      }
      return products;
    },
  },
  watch:{
    // 'activeCategory'(newVal){
    //   if(newVal){
    //   }
    // }
  },
  methods: {
    ...mapActions({
      fetchFavoriteList: `favorites/GET_FAVORITES_LIST`
    })
  }
}